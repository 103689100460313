import produce from 'immer'

import type { PageMetaFragment } from '../../graphql/fragments/PageMetaFragment.graphql'
import type { StepsFragment } from '../../graphql/fragments/StepsFragment.graphql'
import { EMPTY_SECTION_OF_STEPS } from '../../graphql/placeholders'
import { renderTemplate } from '../../lib/utils/text'

export const personalizeMeta = (
    meta: PageMetaFragment | undefined,
    data: any
) => {
    if (!meta) return meta

    return produce(meta, (draft) => {
        const keys = [
            'title',
            'description',
            'ogDescription',
            'ogTitle',
        ] as const

        for (const key of keys) {
            draft[key] = renderTemplate(draft[key], data)
        }
    })
}

export const personalizeStepsSection = (
    steps: Omit<StepsFragment, 'sys' | 'type'>,
    data: Record<string, any>
) => {
    return produce(steps ?? EMPTY_SECTION_OF_STEPS, (draft) => {
        const { section, steps, action } = draft

        if (section) {
            section.title = renderTemplate(section.title, data)
            section.subtitle = renderTemplate(section.subtitle, data)
        }

        if (action && 'title' in action) {
            action.title = renderTemplate(action.title, data)
        }

        if (steps) {
            for (const item of steps.items) {
                item.title = renderTemplate(item.title, data)
                item.subtitle = renderTemplate(item.subtitle, data)
            }
        }
    })
}
