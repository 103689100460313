import { makeQueryFunction, makeUseQueryHook } from '../functions'
import { EMPTY_PAGE_META } from '../placeholders'
import { GetPageMetaDocument } from '../queries/GetPageMeta.graphql'
import type { PageHookOptions } from './page'

export type PageMetaHookOptions = Omit<PageHookOptions, 'slug'> & {
    /** Sys.id of the meta object */
    id: string
}

/**
 * Returns postprocessed contents for a page for given slug.
 */
export function usePageMeta({ id, enabled = true }: PageMetaHookOptions) {
    const result = useGetPageMeta({ variables: { id }, pause: !enabled })
    return {
        ...result,
        meta: result.data?.meta ?? EMPTY_PAGE_META,
    }
}

export const getPageMeta = makeQueryFunction({ query: GetPageMetaDocument })

const useGetPageMeta = makeUseQueryHook({
    query: GetPageMetaDocument,
})
